import React from 'react';

const Input = ({
  id,
  name,
  type,
  label,
  isRequired = false,
  value,
  helper,
  placeholder,
  error,
  extraData,
  classes = {},
  events = {},
  register = () => {},
  validationSchema = {},
}) => {
  const { fieldClasses = '', labelClasses = 'singup-title-control', groupClasses = '' } = classes;
  const { onChange, ...restEvents } = events;

  const handleChange = (e) => {
    if (typeof onChange === 'function') {
      onChange({
        value: e.target.value,
      });
    }
  };

  return (
    <div className={`form-group ${groupClasses}`}>
      {label && (
        <div>
          <label
            htmlFor={id}
            className={labelClasses}>
            {label}
            {(!isRequired || !validationSchema) && (
              <span className="me-2 text-gray">(اختياري)</span>
            )}
          </label>
        </div>
      )}
      <input
        type={type}
        id={id}
        className={`form-control ${fieldClasses} ${error ? 'is-invalid' : ''}`}
        name={name}
        defaultValue={value}
        placeholder={placeholder}
        required={isRequired}
        onChange={handleChange}
        {...restEvents}
        {...extraData}
        {...register(name, validationSchema)}
      />
      {error && (
        <small className="invalid-feedback">{error?.message || error}</small>
      )}
      {helper && <small className="form-text text-muted">{helper}</small>}
    </div>
  );
};

export default Input;