import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ThankYouPage = () => {
  const [searchParams] = useSearchParams();
  const dash_url = searchParams.get("dash_url");
  const { t } = useTranslation("auth");

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = dash_url;
    }, 2000);

    return () => clearTimeout(timer); // Cleanup the timeout on component unmount
  }, [dash_url]);

  return (
    <Box id="signup-questions">
      <div className="auth-base">
        <div className="auth-content">
          <div className="py-5 px-3">
            <div className="text-center">
              <img
                src="/images/checkmark.circle.fill.svg"
                alt="Success"
                className="mb-4 w-40 mx-auto"
              />
              <h1 className="singup-title-text text-2xl">
                {t("register.thanks")}
              </h1>
              <p className="mt-4">
                {t("register.redirect_message")}
                <a
                  href={dash_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 underline ml-2"
                >
                  {t("register.click_here")}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default ThankYouPage;
