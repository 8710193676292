import React from "react";
import PhoneInput from "react-phone-input-2";
import ar from "react-phone-input-2/lang/ar.json";
import { Controller } from "react-hook-form";

const Tel = ({
  id,
  name,
  label,
  isRequired = false,
  value,
  helper,
  placeholder,
  error,
  extraData,
  classes = {},
  events = {},
  register = () => {},
  validationSchema = {},
  control
}) => {
  const { fieldClasses = "", labelClasses = "", groupClasses = "" } = classes;   

  return (
    <div className={`form-group ${groupClasses}`}>
      <Controller
        control={control}
        name={name}
        rules={validationSchema}
        render={({ field: { ref, ...field } }) => (
          <PhoneInput
            autoFormat={false}
            countryCodeEditable={false}
            country={"sa"}
            onlyCountries={["sa"]}
            {...events}
            {...extraData}
            {...register(name, validationSchema)}
            {...field}
            inputProps={{
              ref,
              id: id,
              required: isRequired,
              placeholder
            }}
            specialLabel={label ? label : "Phone"}
            inputClass={error ? "is-invalid" : ""}
            localization={ar}
          />
        )}
      />
      {error && (
        <small className="invalid-feedback">
          {error?.message || error}
        </small>
      )}
      {helper && <small className="form-text text-muted">{helper}</small>}
    </div>
  );
};

export default Tel;