import Star from '../assets/icons/Star.svg?component';
import  GreenCursor from "../assets/icons/green-cursor.svg?component";
import PurpleCursor from "../assets/icons/purple-cursor.svg?component";
import Dotted from "../assets/icons/dotted.svg?component";
import DottedLine from "../assets/icons/dotted-line.svg?component";
import SmallStar from "../assets/icons/small-star.svg?component";
import Box from "../assets/icons/box.svg?component";
import Location from "../assets/icons/location.svg?component";
import Market from "../assets/icons/market.svg?component";
import Person from "../assets/icons/person.svg?component";
import Wifi from "../assets/icons/wifi.svg?component";
import Cart from "../assets/icons/cart.svg?component";
import Doc from "../assets/icons/document.svg?component";
import Wallet from "../assets/icons/wallet.svg?component";
import Plus from "../assets/icons/plus.svg?component";
import Minus from "../assets/icons/minus.svg?component";
import Menu from "../assets/icons/menu.svg?component";
import Close from "../assets/icons/close.svg?component";
import ArrowUp from "../assets/icons/arrow-up.svg?component";
import Sun from "../assets/icons/sun.svg?component";
import Moon from "../assets/icons/moon.svg?component";
import Whatsapp from "../assets/icons/ic--baseline-whatsapp.svg?component";
import Tiktok from "../assets/icons/ic--baseline-tiktok.svg?component";
import Instagram from "../assets/icons/mdi--instagram.svg?component";
import X from "../assets/icons/pajamas--twitter.svg?component";

export const icons = {
  star: <Star />,
  gc: <GreenCursor />,
  pc: <PurpleCursor />,
  dotted: <Dotted />,
  dottedLine: <DottedLine />,
  smallStar: <SmallStar />,
  box: <Box />,
  location: <Location />,
  market: <Market />,
  person: <Person />,
  wifi: <Wifi />,
  cart: <Cart />,
  doc: <Doc />,
  Wallet: <Wallet />,
  plus: <Plus />,
  minus: <Minus />,
  menu: <Menu />,
  close: <Close />,
  up: <ArrowUp />,
  sun: <Sun />,
  moon: <Moon />,
  whatsapp: <Whatsapp />,
  tiktok: <Tiktok />,
  instagram: <Instagram />,
  x: <X />,
};
